import React from "react"
import Layout from "../components/layout"

function About() {
  return (
    <Layout>
      <div>About us</div>
    </Layout>
  )
}

export default About
